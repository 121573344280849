import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {AlertDialogComponent} from '../components/shared/alert-dialog/alert-dialog.component';
import {DateOptions} from '../interfaces/date-options';
import {ConfirmBookingDialogComponent} from './confirm-booking-dialog/confirm-booking-dialog.component';
import {Location} from '@angular/common';
import {GroomBookingTypeGuideComponent} from './groom-booking-type-guide/groom-booking-type-guide.component';
import {differenceInCalendarWeeks} from 'date-fns/esm/fp';
import {
  addDays,
  addWeeks,
  differenceInCalendarDays,
  format,
  isBefore,
  isSameDay,
  isSameMonth,
  parseISO,
  subDays
} from 'date-fns';
import {AvailabilityParams, BookingService, EMERGENCY_APPT_TYPE} from '../services/booking.service';
import {PatientService} from '../patients/patient.service';
// tslint:disable-next-line:max-line-length
import {EmergencyAppointmentDialogComponent} from '../components/shared/emergency-appointment-dialog/emergency-appointment-dialog.component';
import {environment} from '../../environments/environment';
import {
  BookingItem,
  GetBookingSlotsParams, MultiAppointmentItem,
  VbBookableColumn,
  VbSlotResponse
} from '@appyvet/vetbooker-definitions/dist/bookings';
import {VbExtendedPatient, VbPatient} from '@appyvet/vetbooker-definitions/dist/patient';
import {Resource} from '@appyvet/vetbooker-definitions/dist/resource';
import {VbClient} from '@appyvet/vetbooker-definitions/dist/client';
import {PurePatient, SelectedPatient} from '@appyvet/vetbooker-definitions/dist/appointments';
import {AppointmentType} from '@appyvet/vetbooker-definitions/dist/appointment_type';
import {GetMonthSlotsResult} from '@appyvet/vetbooker-definitions/definitions/interfaces/bookings';
import {AppointmentSettings, ClientPatientDetails} from '@appyvet/vetbooker-definitions/dist/client_patient_details';
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {PaymentService} from "../payments/payments.service";
import {AuthService} from "../auth/auth.service";

@Component({
  selector: 'app-booker',
  templateUrl: './booker.component.html',
  styleUrls: ['./booker.component.scss', '../components/common-components/patient-selector/patient-selector.scss']
})
export class BookerComponent implements OnInit, OnDestroy {

  patientShow: boolean;
  resourceShow: boolean;

  isGroomRoom = environment.GROOM;
  isReferral: boolean;
  bookerText: string;
  bookingReturnUrl?: string;
  bookerWarning: string;
  selectedPetNames$ = new BehaviorSubject<string>('Select pets');
  dateOptions$ = new BehaviorSubject<DateOptions>(null);
  minDaysBeforeBooking$ = new BehaviorSubject<number>(null);
  maxDays$ = new BehaviorSubject<number>(0);
  patients$ = new BehaviorSubject<VbExtendedPatient[]>([]);
  resources: Resource[] = [];
  currentAppointment: MultiAppointmentItem;
  originalAppointment: MultiAppointmentItem;
  appointmentBookerTypes: AppointmentType[];
  selectedDate$ = new BehaviorSubject<Date>(new Date());
  selectedPatients$ = new BehaviorSubject<SelectedPatient[]>([]);
  selectedPatients: SelectedPatient[] = [];
  APPOINTMENT_DEFAULT: string;
  selectedResource$ = new BehaviorSubject<Resource>(null);
  selectedResource: Resource;
  clinicDateOptions: DateOptions;
  resourceDateOptions: DateOptions = {} as DateOptions;

  ADULT_AGE_IN_WEEKS = 25;
  GROOM_MAX_DAYS_AVAILABILITY = 84;
  maxPatients: number;
  hideType: boolean;
  selectedClient: VbClient;
  internalAppointmentType: string;
  clinicName: string;
  screenWidth: number;
  loading$ = new BehaviorSubject<boolean>(false);
  empty$ = new BehaviorSubject<boolean>(false);
  editing$ = new BehaviorSubject<boolean>(true);
  clipboardSlots$ = new BehaviorSubject<VbBookableColumn[]>([]);
  bookingType = 'primary';
  nextApptAnalysis: string;
  loggerId: string;
  price: number;
  error$ = new BehaviorSubject<string>(null);
  patientBookingText$ = new BehaviorSubject<string>('');
  originalAppointmentFormattedDate: string;
  isEdit$ = new BehaviorSubject<boolean>(false);
  showHolidayMessage$ = new BehaviorSubject<boolean>(false);
  private editingApptId: string;
  maxPatientsMessage: string;
  clipboardSearchResults$ = new BehaviorSubject<VbSlotResponse[]>(null);
  private clipboardSearchResults: GetMonthSlotsResult;
  isRequestType$ = new BehaviorSubject<boolean>(false);
  private params: Params;
  private dialogSubscription: Subscription;
  private patientSubscription: Subscription;
  private selectedPatientsSubscription: Subscription;
  private routeParamsSubscription: Subscription;
  private queryParamsSubscription: Subscription;
  private editingApptSubscription: Subscription;
  private slotsSubscription: Subscription;
  private selectedDateSub: Subscription;
  private routeSub: Subscription;
  private emergencyEndDate: Date;
  isBeforeEmergencyEndDate: boolean;
  private clinicTel: string;
  publicHolidayMessage: string;
  depositStatus: { depositOnFile: boolean, depositId: string | null } | null = null;
  aptSettings: AppointmentSettings;

  constructor(
    private patientService: PatientService,
    private bookingService: BookingService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private gaService: GoogleAnalyticsService,
    private location: Location,
    private router: Router,
    private paymentService: PaymentService,
    public authService: AuthService
  ) {
    this.getDepositStatus();
  }

  ngOnDestroy(): void {
    this.patientService.resetPatients();
    this.dialogSubscription?.unsubscribe();
    this.patientSubscription?.unsubscribe();
    this.routeSub?.unsubscribe();
    this.selectedPatientsSubscription?.unsubscribe();
    this.routeParamsSubscription?.unsubscribe();
    this.queryParamsSubscription?.unsubscribe();
    this.editingApptSubscription?.unsubscribe();
    this.slotsSubscription?.unsubscribe();
    this.selectedDateSub?.unsubscribe();
  }

  async getDepositStatus(): Promise<void> {
    this.depositStatus = await this.paymentService.getDepositStatus();
  }

  showBookerTypeHelper() {
    this.dialog.open(GroomBookingTypeGuideComponent,
      {panelClass: 'no-padding-dialog', maxWidth: this.screenWidth > 400 ? '80vw' : '100vw'});
  }

  getPatientAge(patient: VbPatient) {
    return patient.dateOfBirth ? differenceInCalendarWeeks(parseISO(patient.dateOfBirth), new Date()) : 0;
  }

  getPatientMinAge(patient: PurePatient) {
    return format(addWeeks(patient.dateOfBirth, 12), 'dd/MM/yyyy');
  }

  getPatientMaxAge(patient: PurePatient) {
    return format(addWeeks(patient.dateOfBirth, 26), 'dd/MM/yyyy');
  }

  setDateOptions(initialConfig: boolean) {
    let satAppointmentTypeDateOptions = false;
    let sunAppointmentTypeDateOptions = false;
    this.selectedPatients.forEach(patient => {
      if (patient.selectedAppointmentType?.disallowSaturdays) {
        satAppointmentTypeDateOptions = true;
      }
      if (patient.selectedAppointmentType?.disallowSundays) {
        sunAppointmentTypeDateOptions = true;
      }
    });
    const minDays = this.minDaysBeforeBooking$.getValue() ? this.minDaysBeforeBooking$.getValue()
      : this.clinicDateOptions?.minDaysBeforeBooking || 0;
    const dateParams: DateOptions =
      {
        satDisallowed: this.clinicDateOptions?.satDisallowed ? this.clinicDateOptions?.satDisallowed
          : this.resourceDateOptions.satDisallowed
            ? this.resourceDateOptions.satDisallowed
            : satAppointmentTypeDateOptions,
        sunDisallowed: this.clinicDateOptions?.sunDisallowed ? this.clinicDateOptions?.sunDisallowed
          : this.resourceDateOptions.sunDisallowed
            ? this.resourceDateOptions.sunDisallowed
            : sunAppointmentTypeDateOptions,
        minDaysBeforeBooking: minDays,
        maxDays: this.maxDays$.getValue()
      };
    if (environment.GROOM) {
      dateParams.firstDate = this.calculateFirstAvailableDate();
      dateParams.lastDate = this.calculateLastAvailableDate();
    }
    this.dateOptions$.next(dateParams);
    const firstAvailable: Date = minDays > 0 ? addDays(new Date(), minDays) : new Date();
    if (initialConfig) {
      this.selectedDate$.next(firstAvailable);
    }
  }

  getPetNames(petsArray) {
    let patients = '';
    for (let i = 0; i < petsArray.length; i++) {
      patients += petsArray[i].patient.patientName;
      if (i < petsArray.length - 2) {
        patients += ', ';
      } else if (i === petsArray.length - 2) {
        patients += ' and ';
      }
    }
    return patients;
  }

  getAdultDogs() {
    return this.selectedPatients.filter(patient => patient.patient.ageInWeeks > this.ADULT_AGE_IN_WEEKS);
  }

  getAdultDogsNames() {
    return this.getPetNames(this.getAdultDogs());
  }


  calculateFirstAvailableDate(): Date {
    let totalMinDays = 0;
    let minDaysForAge = 0;
    let minDaysForEmergency = 0;
    const patientsOverDateLimit = [];
    this.selectedPatients.forEach(patient => {
      if (!patient.patient.dateOfBirth) {
        return;
      }

      minDaysForAge = differenceInCalendarDays(
        addWeeks(patient.patient.dateOfBirth, patient.selectedAppointmentType?.minAge), new Date());
      if (this.isBeforeEmergencyEndDate) {
        if (patient.selectedAppointmentType && !patient.selectedAppointmentType.emergencyAppt) {
          minDaysForEmergency = differenceInCalendarDays(this.emergencyEndDate, new Date());
        }
      }
      if (minDaysForAge > this.GROOM_MAX_DAYS_AVAILABILITY) {
        patientsOverDateLimit.push(patient);
      }
      totalMinDays = Math.max(minDaysForAge, totalMinDays, minDaysForEmergency);
    });
    this.minDaysBeforeBooking$.next(totalMinDays);
    // 09/10/20 - remove dialog as per change in requirements
    // if (totalMinDays > this.GROOM_MAX_DAYS_AVAILABILITY) {
    //   const petNames = this.getPetNames(patientsOverDateLimit);
    // const alertDialogConfig = new MatDialogConfig();
    // alertDialogConfig.data = {
    //   confirmationText: 'Got it!',
    //   message: 'Sorry, because of the ages and types of groom selected for ' + petNames +
    //     ', there are no dates available to book. Please remove ' + petNames + ' or change their selected groom type'
    // + (patientsOverDateLimit.length > 1 ? 's' : '') + ' to book.' }; this.dialog.open(AlertDialogComponent,
    // alertDialogConfig); }
    return addDays(new Date(), totalMinDays);
  }

  calculateLastAvailableDate(): Date {
    let totalMaxDays = this.GROOM_MAX_DAYS_AVAILABILITY;
    let lastDay = this.GROOM_MAX_DAYS_AVAILABILITY;
    let lastDaysForEmergency = this.GROOM_MAX_DAYS_AVAILABILITY;
    this.selectedPatients.forEach(patient => {
      if (patient.selectedAppointmentType?.maxAge !== -1 && patient.patient.dateOfBirth) {
        lastDay = differenceInCalendarDays(
          addWeeks(patient.patient.dateOfBirth, patient.selectedAppointmentType?.maxAge + 1), new Date());
      }
      if (this.isBeforeEmergencyEndDate) {
        if (patient.selectedAppointmentType && patient.selectedAppointmentType.emergencyAppt) {
          lastDaysForEmergency = differenceInCalendarDays(this.emergencyEndDate, new Date());
        }
      }
      totalMaxDays = Math.min(lastDay, totalMaxDays, lastDaysForEmergency);
    });
    this.maxDays$.next(totalMaxDays);
    return addDays(new Date(), totalMaxDays);
  }

  filterAppointmentTypesByResource(): AppointmentType[] {
    let appointmentTypeRemoved = false;
    const filteredApptTypes: AppointmentType[] = [];
    if (this.selectedResource) {
      this.appointmentBookerTypes.forEach(apptType => {
        if (apptType.resources?.length > 0) {
          apptType.resources.forEach(resource => {
            if (resource === this.selectedResource.objectId) {
              filteredApptTypes.push(apptType);
            }
          })
        } else {
          filteredApptTypes.push(apptType);
        }
      });
    } else {
      return this.appointmentBookerTypes;
    }
    this.selectedPatients.forEach(selectedPatient=>{
      let patientApptExistsForResource = false;
      if(selectedPatient.selectedAppointmentType){
        if(filteredApptTypes.find(apptType=> apptType.objectId === selectedPatient.selectedAppointmentType.objectId)){
          patientApptExistsForResource = true;
        }
      }
      if(!patientApptExistsForResource && selectedPatient.selectedAppointmentType){
        appointmentTypeRemoved = true;
        selectedPatient.selectedAppointmentType = null;
      }
    });
    if(appointmentTypeRemoved){
      this.dialog.open(AlertDialogComponent,
        {
          data: {
            message: 'One of your pets had an appointment type selected which is not available at this location. Please select a new appointment type'
          }
        });
    }
    return filteredApptTypes;
  }

  setSelectedResource(resource: Resource, updateRouteParams: boolean) {
    this.selectedResource$.next(resource);
    this.selectedResource = resource;
    this.filterAppointmentTypesByResource();
    this.resourceShow = false;
    this.resourceDateOptions = {
      satDisallowed: resource.satDisallowed,
      sunDisallowed: resource.sunDisallowed,
    } as DateOptions;
    this.isRequestType$.next(resource.listType);
    this.setDateOptions(false);
    if (updateRouteParams) {
      this.updateRouteParams(true);
    }
    this.scrollToBottom();
  }

  loadAmendData() {
    // Copy original appt.
    this.originalAppointment = JSON.parse(JSON.stringify(this.currentAppointment));
    this.originalAppointmentFormattedDate = format(parseISO(this.originalAppointment.utcDate),
      'HH:mm \'on\' EEEE, MMMM do yyyy');
  }

  patientsCompleted() {
    if(this.selectedPatients.length ===0){
      return false;
    }
    this.selectedPatients.forEach(selectedPatient=>{
      if(!selectedPatient.selectedAppointmentType){
        return false;
      }
    });
    return true;
  }

  setSelectedAppointmentType(patient: SelectedPatient, appointmentType: AppointmentType, updateRouteParams: boolean) {
    this.gaService.event(
      'Select Appointment Type - ' + appointmentType.displayName,
      'Appointment Booker',
      this.aptSettings?.takeDeposit && this.aptSettings?.depositAmount ? `${this.clinicName} - £${this.aptSettings.depositAmount} deposit` : `${this.clinicName} - No deposit`
    );
    if (appointmentType.displayName === EMERGENCY_APPT_TYPE) {
      this.dialog.open(EmergencyAppointmentDialogComponent);
    }
    if (appointmentType.notBookable) {
      this.dialog.open(AlertDialogComponent,
        {
          data: {
            html: `<h2 class="mat-h2 accent-text-color">This service is only available to book by telephone.</h2><p class="mat-body-1">
We’re only able to take appointments for this service by telephone to allow our highly trained groomers to assess your dog’s needs; to book this service please contact our salon directly by calling <a href="tel:${this.clinicTel}">${this.clinicTel}</a>.
We thank you for your patience at this time.</p>`
          }
        });
    } else {
      patient.selectedAppointmentType = appointmentType;
      patient.typeShow = false;
      this.setDateOptions(false);

      if (updateRouteParams) {
        this.updateRouteParams(updateRouteParams);
      }
    }

    this.scrollToBottom();
  }

  updateRouteParams(forceUpdate?: boolean) {
    const passedPatientParams = [];
    this.selectedPatients.forEach(selectedPatient => {
      // Only change params if not default patient, i.e. has patient number
      if (selectedPatient.patient.patientNumber) {
        passedPatientParams.push({
          patientId: selectedPatient.patient.patientNumber,
          appointmentType: selectedPatient.selectedAppointmentType?.objectId,
          notes: selectedPatient.notes
        });
      }
    });
    const urlParams = {
      patients: JSON.stringify(passedPatientParams),
    } as any;
    let dateStr;
    dateStr = this.selectedDate$?.getValue().toJSON();
    if (dateStr) {
      urlParams.date = dateStr;
    }
    if (this.selectedResource$.getValue()) {
      urlParams.resourceId = this.selectedResource$.getValue().objectId;
    }

    if (this.editingApptId && this.editingApptId !== 'null') {
      urlParams.editId = this.editingApptId;
    }

    const url = this
      .router
      .createUrlTree([urlParams], {relativeTo: this.activatedRoute})
      .toString();
    this.location.go(url);
    if (this.isFormCompleted()) {
      if (!forceUpdate && this.clipboardSearchResults && isSameMonth(this.selectedDate$.getValue(),
        this.clipboardSearchResults.days[0].date)) {
        this.clipboardSearchResults.days.forEach(slot => {
          if (isSameDay(slot.date, this.selectedDate$.getValue())) {
            if (slot.isPublicHoliday && this.publicHolidayMessage) {
              this.showPublicHolidayInfo();
            } else {
              this.showHolidayMessage$.next(false);
            }
            this.clipboardSlots$.next(slot.clipboardResults);
          }
        });
      } else {
        this.getSlots();
      }
    } else {
      this.clipboardSearchResults$.next(null);
      this.clipboardSlots$.next(null);
    }
  }

  showPublicHolidayInfo() {
    const alertDialogConfig = new MatDialogConfig();
    alertDialogConfig.data = {
      confirmationText: 'Got it!',
      title: 'Public Holiday',
      message: this.publicHolidayMessage
    };
    this.showHolidayMessage$.next(true);
    this.dialog.open(AlertDialogComponent, alertDialogConfig);
  }

  getGroomBookerTypes(patient: SelectedPatient): AppointmentType[] {
    const returnAppts = [];
    const ageInWeeks = patient.patient.ageInWeeks;
    const breed = this.bookingService.getGroomBreedData(patient.patient);
    this.appointmentBookerTypes.forEach(item => {
      let matchesMin = true;
      let matchesMax = false;
      let noAvailability = false;
      const apptType = item;
      if (ageInWeeks >= apptType.minAge) {
        matchesMin = true;
      }
      if (apptType.maxAge === -1 || ageInWeeks <= apptType.maxAge) {
        matchesMax = true;
      }
      const daysUntilGroomFirstAvailable = patient.patient.dateOfBirth ? differenceInCalendarDays(
        addWeeks(patient.patient.dateOfBirth, apptType.minAge), new Date()) : 0;
      let daysUntilGroomNoLongerAvailable = this.GROOM_MAX_DAYS_AVAILABILITY;
      if (apptType.maxAge > -1 && patient.patient.dateOfBirth) {
        daysUntilGroomNoLongerAvailable = differenceInCalendarDays(
          addWeeks(patient.patient.dateOfBirth, apptType.maxAge), new Date());
      }
      if (daysUntilGroomFirstAvailable > this.GROOM_MAX_DAYS_AVAILABILITY) {
        noAvailability = true;
      }
      if (this.isBeforeEmergencyEndDate) {
        const daysUntilEndOfEmergency = differenceInCalendarDays(this.emergencyEndDate, new Date());
        if (apptType.emergencyAppt) {
          if (daysUntilGroomFirstAvailable > daysUntilEndOfEmergency) {
            noAvailability = true;
          }
        } else {
          if (daysUntilGroomNoLongerAvailable < daysUntilEndOfEmergency) {
            noAvailability = true;
          }
        }
      }
      if (matchesMax && matchesMin && !noAvailability) {
        const lowercaseItemName = item.displayName.toLowerCase();
        if (lowercaseItemName.indexOf('full') !== -1 || (lowercaseItemName.indexOf(
          'welfare') !== -1 && lowercaseItemName.indexOf('puppy') === -1)) {
          if (breed.coat === 'long' && item.isLongHair) {
            returnAppts.push(apptType);
          } else if (breed.coat === 'short' && !item.isLongHair) {
            returnAppts.push(apptType);
          }
        } else {
          returnAppts.push(apptType);
        }
      }
    });
    return returnAppts;
  }

  selectPatient(patient: VbExtendedPatient, updateRouteParams: boolean, passedApptType?: AppointmentType,
                passedNotes?: string) {
    const ageInWeeks = this.getPatientAge(patient);
    const purePatient: PurePatient = {
      patientNumber: patient.patientNumber,
      patientName: patient.name,
      patientBreed: patient.breedItem,
      ageInWeeks,
      dateOfBirth: patient.dateOfBirth ? new Date(patient.dateOfBirth) : new Date()
    };
    if (patient.breedItem.notOnline || patient.breedItem.neverBook) {
      const notOnlineText = `The breed you have selected has a corded coat, which
      requires specialist grooming. To make an appointment for
      this breed, please contact the salon directly on ${this.clinicTel} to make an appointment and to discuss your dog’s
      individual grooming requirements.`;
      const neverBookText = `You have selected a breed that we’re unable to groom, including
but not limited to breeds that are crossed with a Pit Bull Terrier,
Dogo Argentino, Japanese Tosa, Fila Brasileiro, Czechoslovakian
Wolfdog, Saarloos Wolfhound/Wolfdog or any wolf hybrid. We
will not groom any dog that must be registered under the
Dangerous Dogs Act 1991, the Dangerous Dogs (Amendment)
Act 1997 or any further amendments to this Act.`;
      const alertDialogConfig = new MatDialogConfig();
      alertDialogConfig.data = {
        confirmationText: 'Got it!',
        title: 'Customer Notice',
        message: patient.breedItem.neverBook ? neverBookText : notOnlineText
      };
      this.dialog.open(AlertDialogComponent, alertDialogConfig);
      return;
    } else {
      let patientMatch = false;
      for (let i = 0; i < this.selectedPatients.length; i++) {
        if (this.selectedPatients[i].patient.patientNumber === patient.patientNumber) {
          patient.isSelected = false;
          this.selectedPatients.splice(i, 1);
          patientMatch = true;
        }
      }
      if (!patientMatch) {
        if (this.selectedPatients?.length > 0 && this.selectedPatients.length === this.maxPatients) {
          const alertDialogConfig = new MatDialogConfig();
          alertDialogConfig.data = {
            confirmationText: 'Got it!',
            title: 'Maximum Number',
            message: this.maxPatientsMessage
          };
          this.dialog.open(AlertDialogComponent, alertDialogConfig);
          return;
        }
        patient.isSelected = true;
        const newObject: SelectedPatient = {
          selectedAppointmentType: undefined,
          patient: purePatient
        };
        this.selectedPatients.push(newObject);
        if (this.appointmentBookerTypes.length === 1) {
          this.setSelectedAppointmentType(this.selectedPatients[this.selectedPatients.length - 1],
            this.appointmentBookerTypes[0], true);
        }
        // Following two calls if an amend
        if (this.internalAppointmentType) {
          this.appointmentBookerTypes.forEach(apptType => {
            if (apptType.internalType && apptType.internalType.length > 0) {
              apptType.internalType.forEach(type => {
                if (type === this.internalAppointmentType) {
                  this.setSelectedAppointmentType(this.selectedPatients[this.selectedPatients.length - 1], apptType,
                    true);
                }
              });
            }
          });
        }
        if (passedApptType) {
          this.setSelectedAppointmentType(this.selectedPatients[this.selectedPatients.length - 1], passedApptType,
            true);
        }
        if (passedNotes) {
          this.selectedPatients[this.selectedPatients.length - 1].notes = passedNotes;
        }
      }
      if (this.isGroomRoom) {
        this.calculateFirstAvailableDate();
      }
      // Check if max patients reached, if so, open the next selector (resource)
      if (this.maxPatients === 0 || this.selectedPatients.length === this.maxPatients) {
        // Check if any unselected. Show first unselected appt type.

      }
      if (updateRouteParams) {
        this.updateRouteParams(updateRouteParams);
      }
      this.selectedPatients$.next(this.selectedPatients);
    }
  }

  loadBookingFromParams(params) {
    if (params.date) {
      this.selectedDate$.next(new Date(params.date));
    }
    if (params.appointmentId) {
      this.bookingService.setEditingAppointment(params.appointmentId);
    }
    if (params.editId && params.editId !== 'null') {
      this.editingApptId = params.editId;
      this.bookingService.setEditingAppointmentById(params.editId);
    }
    if (params.patients) {
      const parsedParams = JSON.parse(params.patients);
      parsedParams.forEach(passedPatient => {
        let patientApptType;
        if (passedPatient.appointmentType) {
          patientApptType = this.appointmentBookerTypes.find(
            apptType => apptType.objectId === passedPatient.appointmentType);
        }
        this.selectPatient(this.patientService.findPatient(passedPatient.patientId), false, patientApptType,
          passedPatient.notes);
      });
    }
    if (params.internalType) {
      this.internalAppointmentType = params.internalType;
    }
    if (params.resourceId && params.resourceId !== 'undefined') {
      this.setSelectedResource(this.resources.find(resource => resource.objectId === params.resourceId), false);
      if(this.isFormCompleted()) {
        this.getSlots();
      }
    }
  }

  setBookerDay(date: Date, updateRouteParams: boolean) {
    this.selectedDate$.next(date);
    if (updateRouteParams) {
      this.updateRouteParams();
    }
  }

  loadAvailabilityCheckerData(patients: VbExtendedPatient[]) {
    const availabilityParams: AvailabilityParams = this.bookingService.getAvailabilityParams();
    if (availabilityParams) {
      this.selectedDate$.next(new Date(availabilityParams.item.clinicDateTime));
      if (patients.length === 1) {
        this.selectPatient(patients[0], true, availabilityParams.groom);
      }
    }
  }

  initialConfig() {
    this.patientSubscription = this.patientService.patients$.subscribe(patients => {
      this.patients$.next(patients);
      if (patients) {
        if (environment.GROOM) {
          this.loadAvailabilityCheckerData(patients);
        }
      }
    });
    this.selectedClient = this.bookingService.client;
    this.clinicName = this.bookingService.clinicName;
    this.clinicTel = this.bookingService.clinicTel;
    this.emergencyEndDate = this.bookingService.emergencyEndDate;
    this.isBeforeEmergencyEndDate = isBefore(new Date(), this.bookingService.emergencyEndDate);
    this.nextApptAnalysis = this.bookingService.nextApptAnalysis;
    // if (!this.bookingService.isReferral && this.patients.length > 1) {
    //   if (this.maxPatients > 0) {
    //     if (this.maxPatients === 1) {
    //       this.PATIENT_DEFAULT += "a pet";
    //     } else {
    //       if (this.maxPatients < this.patients.length) {
    //         this.PATIENT_DEFAULT += "a maximum of " + this.maxPatients + " ";
    //       } else {
    //         this.PATIENT_DEFAULT += " pets";
    //       }
    //     }
    //   }
    // } else {
    //   this.PATIENT_DEFAULT += "a pet"
    // }
    this.setDateOptions(true);
  }

  configureSettings(appointmentSettings: AppointmentSettings) {
    this.maxDays$.next(appointmentSettings.maxDays);
    this.clinicDateOptions = {
      satDisallowed: appointmentSettings.satDisallowed,
      sunDisallowed: appointmentSettings.sunDisallowed,
      minDaysBeforeBooking: appointmentSettings.minDaysBeforeBooking,
      maxDays: appointmentSettings.maxDays,
    };
    this.maxPatients = appointmentSettings.maxPatients;
    this.bookerText = appointmentSettings.bookerText;
    this.bookingReturnUrl = appointmentSettings.bookingReturnUrl;
    this.bookerWarning = appointmentSettings.bookerWarning;
    this.hideType = appointmentSettings.defaultApptType;
    this.maxPatientsMessage = appointmentSettings.maxPatientsMessage;
    this.publicHolidayMessage = appointmentSettings.publicHolidayMessage;
  }

  ngOnInit() {
    this.aptSettings = this.bookingService.appointmentSettings$.getValue();

    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
    };
    // For some reason, on renavigating, the selected patients carried over. reset to empty on init.
    this.selectedPatients$.next([]);
    this.routeSub = this.activatedRoute.data
      .subscribe((data: { clientPatientDetails: ClientPatientDetails }) => {
        this.appointmentBookerTypes = data.clientPatientDetails.appointmentTypes;
        this.resources = data.clientPatientDetails.resources;
        if (this.resources.length === 1) {
          // TODO - use client preferred clinic if available.
          this.setSelectedResource(this.resources[0], true);
        }
        this.configureSettings(data.clientPatientDetails.appointmentSettings);
        this.initialConfig();
      });
    this.isReferral = this.bookingService.isReferral;
    this.selectedPatientsSubscription = this.selectedPatients$.subscribe(selectedPatients => {
      if (selectedPatients.length > 0) {
        this.selectedPetNames$.next(this.getPetNames(selectedPatients));
        this.patientBookingText$.next(this.bookingService.getPatientBookingText(selectedPatients));
      } else {
        const blankPetText = 'Select pet' + (this.maxPatients > 1 ? 's' : '');
        this.selectedPetNames$.next(blankPetText);
      }
    });
    this.routeParamsSubscription = this.activatedRoute.params.subscribe(params => {
      if (params) {
        this.params = params;
        if (!params.resourceId) {
          this.setResourceByPreferredClinic();
        }
        this.loadBookingFromParams(params);
      } else {
        this.setResourceByPreferredClinic();
        // TODO - As params can change, need to reload data here after notified of change.
      }
    });
    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(params => {
      if (params.patientNumber) {
        this.selectPatient(this.patientService.findPatient(params.patientNumber), false);
      }
    });
    // Must do this second, so load amend data rather than state param data if edit
    this.editingApptSubscription = this.bookingService.editingAppointment$.subscribe(
      (editingAppt: MultiAppointmentItem) => {
        if (editingAppt) {
          this.isEdit$.next(true);
          this.currentAppointment = editingAppt;
          this.editingApptId = editingAppt.apptId;
          this.loadAmendData();
          // this.updateRouteParams();
          // this.bookingService.editingAppointment$.next(null);
        }
      });
  }

  isDefaultType(patient?: SelectedPatient) {
    if (this.hideType) {
      return false;
    }
    if (patient) {
      return !patient.selectedAppointmentType?.displayName;
    }
    let defaultTypes = false;
    this.selectedPatients.forEach(selectedPatient => {
      if (!selectedPatient.selectedAppointmentType?.displayName) {
        defaultTypes = true;
      }
    });
    return defaultTypes;
  }


  appointmentTypeSelected(patient?: SelectedPatient) {
    if (patient) {
      return patient.selectedAppointmentType?.objectId;
    }
    let hasUnselectedTypes = false;
    this.selectedPatients.forEach(selectedPatient => {
      if (!selectedPatient.selectedAppointmentType || !selectedPatient.selectedAppointmentType.objectId) {
        hasUnselectedTypes = true;
      }
    });
    return !hasUnselectedTypes;
  }

  isFormCompleted() {
    return this.selectedResource && this.patientsCompleted() && !this.isDefaultType();
  }

  scrollToBottom() {
    if (this.isFormCompleted()) {
      setTimeout(() => {
        const scroller = document.querySelector('mat-sidenav-content');
        scroller.scrollTo({behavior: 'smooth', top: scroller.scrollHeight});
      }, 200);
    }
  }

  getSlots() {
    // if (this.isFormCompleted()) {
    this.empty$.next(false);
    this.error$.next(null);
    this.editing$.next(false);
    this.loading$.next(true);
    this.clipboardSlots$.next([]);
    const selectedResource = this.selectedResource$.getValue();
    const selectedDate = this.selectedDate$.getValue();
    let searchDate = this.selectedDate$.getValue();
    const firstAvailableDate = addDays(new Date(), this.dateOptions$.getValue().minDaysBeforeBooking);
    if (!isSameMonth(selectedDate, firstAvailableDate)) {
      searchDate = new Date(searchDate.getFullYear(), searchDate.getMonth(), 1);
    } else {
      searchDate = firstAvailableDate;
    }
    // Send only required data
    const bookingItem: GetBookingSlotsParams = {
      resourceId: selectedResource.objectId,
      date: format(searchDate, 'yyyy-MM-dd'),
      patients: this.selectedPatients,
      type: this.bookingType
    } as GetBookingSlotsParams;
    // TODO - referrals for bookings
    // if (this.isReferral) {
    //   bookingItem.referralClinicId = this.referralClinic.objectId;
    // }
    this.gaService.event(
      'Find Slots',
      'Appointment Booker',
      this.aptSettings?.takeDeposit && this.aptSettings?.depositAmount ? `${this.clinicName} - £${this.aptSettings.depositAmount} deposit` : `${this.clinicName} - No deposit`
    );
    bookingItem.nextApptAnalysis = this.nextApptAnalysis;
    this.slotsSubscription = this.bookingService.getSlots(bookingItem).subscribe(clipboardSlots => {
      if (clipboardSlots) {
        this.loading$.next(false);
        if (clipboardSlots.days.length > 0) {
          this.clipboardSearchResults = clipboardSlots;
          this.clipboardSearchResults$.next(clipboardSlots.days);
          clipboardSlots.days.forEach(day => {
            if (isSameDay(day.date, selectedDate)) {
              if (day.isPublicHoliday) {
                this.showPublicHolidayInfo();
              } else {
                this.showHolidayMessage$.next(false);
              }
              this.clipboardSlots$.next(day.clipboardResults);
            }
          });
          this.price = clipboardSlots.price;
          this.loggerId = clipboardSlots.loggerId;
        } else {
          this.gaService.event(
            'Find Slots None Found',
            'Appointment Booker',
            this.aptSettings?.takeDeposit && this.aptSettings?.depositAmount ? `${this.clinicName} - £${this.aptSettings.depositAmount} deposit` : `${this.clinicName} - No deposit`
          );
          this.empty$.next(true);
        }
      }
    }, error => {
      this.loading$.next(false);
      this.clipboardSearchResults = null;
      this.clipboardSearchResults$.next(null);
      this.empty$.next(true);
      this.error$.next(error);
    });

  }

  setSelectedTime(item) {
    const booking: BookingItem = {
      clientNumber: this.selectedClient?.clientNumber, notes: '',
      resourceId: this.selectedResource.objectId,
      nextApptAnalysis: this.nextApptAnalysis,
      selectedPatients: this.selectedPatients$.getValue(),
      loggerId: this.loggerId,
      date: format(this.selectedDate$.getValue(), 'yyyy-MM-dd'),
      item,
    };
    this.gaService.event(
      'Select Slot',
      'Appointment Booker',
      this.aptSettings?.takeDeposit && this.aptSettings?.depositAmount ? `${this.clinicName} - £${this.aptSettings.depositAmount} deposit` : `${this.clinicName} - No deposit`
    );
    const bookerDialogConfig = new MatDialogConfig();
    bookerDialogConfig.data = {
      booking,
      selectedResource: this.selectedResource$.getValue(),
      publicHolidayMessage: this.showHolidayMessage$.getValue() ? this.publicHolidayMessage : null,
      editId: this.editingApptId,
      takeDeposit: this.aptSettings?.takeDeposit,
      depositAmount: this.aptSettings?.depositAmount,
      clientHasDeposit: this.depositStatus ? this.depositStatus.depositOnFile : this.bookingService.clientHasDeposit$.getValue(),
    };
    bookerDialogConfig.panelClass = 'no-padding-dialog';
    bookerDialogConfig.maxWidth = this.screenWidth > 400 ? '80vw' : '100vw';
    bookerDialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(ConfirmBookingDialogComponent, bookerDialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.bookingReturnUrl) {
          window.location.href = this.bookingReturnUrl;
        } else {
          this.router.navigateByUrl('history');
        }
      }
    });
  }

  onMonthChange(month: Date) {
    this.setBookerDay(month, true);
  }

  private setResourceByPreferredClinic() {
    for (const resource of this.resources) {
      // Select first resource that matches client preferred clinic ID
      if (resource.clinicId === this.bookingService.client.clinicId) {
        this.setSelectedResource(resource, false);
        break;
      }
    }
  }

  getAppointmentTypes(patient: SelectedPatient) {
    if (environment.GROOM) {
      return this.getGroomBookerTypes(patient);
    } else {
      return this.filterAppointmentTypesByResource();
    }
  }

  getFirstAdultBookableDate(patient: PurePatient) {
    const firstBooking = addWeeks(patient.dateOfBirth || new Date(), 26);
    return format(subDays(firstBooking, this.GROOM_MAX_DAYS_AVAILABILITY), 'dd/MM/yyyy');
  }

  showAppointmentDescription(appointmentType: AppointmentType) {
    this.dialog.open(AlertDialogComponent,
      {data: {title: appointmentType.displayName, message: appointmentType.description}});
  }
}
