<ng-template #loadingBlock>
  <div fxLayout="row" fxLayoutGap="20px">
    <mat-progress-spinner color="primary" diameter="50"
                          mode="indeterminate"></mat-progress-spinner>
    <p>Retrieving next available appointments</p></div>
</ng-template>
<div *ngIf="!(loading$|async) else loadingBlock" fxLayout="column"
     fxLayoutGap="20px">
  <ng-container *ngIf="patientReminders$ | async as patientReminders">
    <ng-container *ngIf="patientReminders.length === 0">
      <mat-card fxFlexFill>
        <mat-card-content>
          <p class="content-text">No reminders found for any patients</p>
        </mat-card-content>
      </mat-card>
    </ng-container>
    <ng-container *ngFor="let patientReminder of patientReminders">
      <mat-card *ngFor="let reminder of patientReminder.reminders"
                class="quick-book-card">
        <ng-container *ngIf="reminder.slots[0]">
          <mat-card-content>
            <div fxLayout="row" fxLayout.xs="column"
                 fxLayoutAlign="space-between center"
                 fxLayoutAlign.xs="start start">
              <div fxFlex.xs="100%" fxLayoutAlign.xs="center center">
                <div *ngIf="reminder.slots[0].photo"
                     [ngStyle]="{'background' : reminder.slots[0].photo | backImg}"
                     class="circular background-image"></div>
              </div>
              <div fxLayout="column">
                <div fxLayout="column" fxLayout.xs="row"
                     fxLayoutAlign.xs="start center" fxLayoutGap.xs="10px">
                  <p class="mat-body-2 item-start item-title">With</p>
                  <p class="mat-body-1">{{reminder.slots[0].clipboardName}}</p>
                </div>
                <div fxLayout="column" fxLayout.xs="row"
                     fxLayoutAlign.xs="start center" fxLayoutGap.xs="10px">
                  <p class="mat-body-2 item-start item-title">Due Date</p>
                  <p
                    class="mat-body-1">{{reminder.reminder.date | dfnsFormat: "EEEE do MMM yyyy" }}</p>
                </div>
              </div>
              <div fxLayout="column">
                <div fxLayout="column" fxLayout.xs="row"
                     fxLayoutAlign.xs="start center" fxLayoutGap.xs="10px">
                  <p class="mat-body-2 item-start item-title">Date</p>
                  <p class="mat-body-1">{{reminder.slots[0].clinicDateTime |
                    dfnsParse: "yyyy-MM-dd'T'HH:mm:ss.SSS": today |
                    dfnsFormat: "EEEE do MMM yyyy 'at' h:mm a"}}</p>
                </div>
                <div fxLayout="column" fxLayout.xs="row"
                     fxLayoutAlign.xs="start center" fxLayoutGap.xs="10px">
                  <p class="mat-body-2 item-start item-title">Where</p>
                  <p class="mat-body-1">{{getResourceName(reminder)}}</p>
                </div>
              </div>
              <div fxLayout="column">
                <div fxLayout="column" fxLayout.xs="row"
                     fxLayoutAlign.xs="start center" fxLayoutGap.xs="10px">
                  <p class="mat-body-2 item-start item-title">Pet</p>
                  <p class="mat-body-1">{{patientReminder.patient.name}}</p>
                </div>
                <div fxLayout="column" fxLayout.xs="row"
                     fxLayoutAlign.xs="start center" fxLayoutGap.xs="10px">
                  <p class="mat-body-2 item-start item-title">For</p>
                  <p class="mat-body-1">{{reminder.reminder.name}}</p>
                </div>
              </div>
              <ng-container *ngIf="canBook && canBookButtonAvailable(reminder)">
                <div fxLayout="row" fxLayoutAlign.xs="center center"
                     class="full-width-xs">
                  <button
                    (click)="bookFirstAvailable(reminder, patientReminder.patient)"
                    color="primary" mat-raised-button>
                    Book
                  </button>
                </div>
              </ng-container>
            </div>
          </mat-card-content>
        </ng-container>
      </mat-card>
    </ng-container>
  </ng-container>
</div>
