import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {BookingService} from '../../services/booking.service';
import {SessionService} from '../../services/session-service.service';
import {formatISO} from 'date-fns';
import {Router} from '@angular/router';
import {
  PatientRemindersSlotResult,
  ReminderSlotRequest,
  SelectedPatient
} from '@appyvet/vetbooker-definitions/dist/appointments';
import {AppointmentType} from '@appyvet/vetbooker-definitions/dist/appointment_type';
import {BaseVbPatient, VbExtendedPatient, VbPatient} from '@appyvet/vetbooker-definitions/dist/patient';
import {Resource} from '@appyvet/vetbooker-definitions/dist/resource';

@Component({
  selector: 'app-patient-reminder-slots',
  templateUrl: './patient-reminder-slots.component.html',
  styleUrls: ['./patient-reminder-slots.component.scss']
})
export class PatientReminderSlotsComponent implements OnInit {
  patientReminders$ = new BehaviorSubject<PatientRemindersSlotResult[]>([]);
  loading$ = new BehaviorSubject<boolean>(true);
  error$ = new BehaviorSubject<string>(null);
  selectedPatient: VbPatient;
  private patients: VbExtendedPatient[];
  private appointmentTypes: AppointmentType[];
  private resources: Resource[];
  private clientNumber: string;
  today = new Date();
  canBook: boolean;

  constructor(private bookingService: BookingService, private sessionService: SessionService, private router: Router) {
  }

  ngOnInit() {
    this.sessionService.clientPatientDetails$.subscribe(clientPatientDetails => {
      if (clientPatientDetails) {
        this.clientNumber = clientPatientDetails.clientData.client.clientNumber;
        this.canBook = clientPatientDetails.acceptingBookings;
        this.bookingService.getVaccineSlotsForClientPatients().subscribe(patientReminders => {
          if (patientReminders) {
            this.loading$.next(false);
            this.patientReminders$.next(patientReminders);
            if (patientReminders.length > 0) {
              this.patients = clientPatientDetails.clientData.patients;
              this.appointmentTypes = clientPatientDetails.appointmentTypes;
              this.resources = clientPatientDetails.resources;
            }
          }
        });
      }
    });
  }
  canBookButtonAvailable(reminderSlot: ReminderSlotRequest) : Boolean{

    const apptType = this.appointmentTypes.find(
      apptTypeToSearch => apptTypeToSearch.objectId === reminderSlot.apptType);

    return apptType ? true : false;

  }
  bookFirstAvailable(reminderSlot: ReminderSlotRequest, patient: BaseVbPatient) {
    const apptType = this.appointmentTypes.find(
      apptTypeToSearch => apptTypeToSearch.objectId === reminderSlot.apptType);
    const resource = this.resources.find(resourceToSearch => resourceToSearch.objectId === reminderSlot.resource);
    const purePatient = this.bookingService.convertExtendPatientToPurePatient(
      this.patients.find(searchPatient => searchPatient.patientNumber === patient.patientNumber));
    this.loading$.next(true);
    const selectedPatient: SelectedPatient = {
      patient: purePatient,
      selectedAppointmentType: apptType,
      notes: 'Quick book',
    };
    this.bookingService.bookAppt({
      clientNumber: this.clientNumber,
      date: formatISO(reminderSlot.slots[0].utcDateTime),
      item: {
        time: reminderSlot.slots[0].time,
        clipboardId: reminderSlot.slots[0].objectId,
        utcDateTime: formatISO(reminderSlot.slots[0].utcDateTime),
        clinicDateTime: reminderSlot.slots[0].clinicDateTime
      },
      notes: 'Quick book online appointment',
      resourceId: resource.objectId,
      selectedPatients: [selectedPatient]
    }).subscribe(result => {
      if (result) {
        this.loading$.next(false);
        this.router.navigateByUrl('/history');
      }
    }, e => {
      this.error$.next(e);
      this.loading$.next(false);
    });
  }

  getResourceName(reminder: ReminderSlotRequest) {
    return this.resources.find(resource => resource.objectId === reminder.resource).name;
  }
}
