import { Injectable } from "@angular/core";
import { BreedItem, SpeciesItem } from "@appyvet/vetbooker-definitions/dist/patient";
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root'
})
export class BreedColorService {
  constructor () {}

  async getBreedsBySpecies(speciesCode: string, clinicCode: string): Promise<BreedItem[]> {
    const params = {
      speciesCode,
      clinicCode,
    }
    return await Parse.Cloud.run('getBreedsBySpeciesId', params);
  }

  async getColoursByBreed(breedId: string, speciesItem: SpeciesItem, clinicCode: string): Promise<BreedItem[]> {
    const params = {
      breedId,
      speciesItem,
      clinicCode,
    }
    return await Parse.Cloud.run('getColoursByBreedId', params);
  }
}
