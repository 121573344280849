<mat-toolbar class="padding-10" color="primary" fxLayout="column">
  <div fxFill fxLayout="row" fxLayoutAlign="space-between center">
    <h3 class="mat-h3">Booking Agreement</h3>

    <button [mat-dialog-close]="true" aria-label="close" mat-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</mat-toolbar>
<mat-dialog-content style="margin:0; padding: 10px">
  <h3 class="mat-h3">By booking an appointment you agree to the following:</h3>
  <h3 class="mat-h3"><b>General</b></h3>
  <p class="mat-body-1">
    • Pets Grooming is a trading name for Pets at Home Limited (“Pets at Home”, “we”, “our”, “us”) and
    is a company registered in England and Wales under company number 01822577. Our VAT number
    is 616 4317 54. Our registered office is Epsom Avenue, Stanley Green Trading Estate, Handforth,
    Cheshire, SK9 3RN.<br>

    <!-- • To secure your booking, we require a £15.00 deposit. You may elect to carry this deposit over to future bookings. Your
    deposit secures against any missed or cancelled appointments and will not be refunded unless you give no less than 48
    hours’ prior notice of cancellation of your booking.
    <br> -->
    • You will be asked to pay a refundable deposit of £15 to secure your appointment when booking.
    This deposit must be paid within 48 hours' of booking your appointment.
    You may elect to carry this deposit over to future bookings. Your deposit secures against any missed
    or cancelled appointments and will not be refunded unless you give no less than 48 hours’ prior
    notice of your booking cancellation.
    <br>
    • If you arrive more than 15 minutes late, we cannot guarantee your appointment will remain
    available and your deposit may not be refunded.
    <br>
    • Any pets not collected at the agreed collection time will incur an additional charge of £5 per 30
    minutes after the collection time stated on the grooming contract.
    <br>
    • Please ensure that your pet has been allowed to be relieved before entering the grooming salon.
    <br>
    • Please do not feed your pet within 30 minutes before their appointment.
    <br>
    • You are responsible for keeping your pet up to date with their vaccinations.
    <br>
    • We recommend waiting at least 2 weeks after a 2nd vaccination before booking a puppy groom.
    <br>
    • You must inform the groomer of any medical conditions and any medications that your pet is
    currently taking. You may be asked for a letter from your vet stating that your pet is healthy enough
    to be groomed before the groom is carried out.
    <br>
    • We will not groom any dog that has been microchipped within the last 24 hours.
    <br>
    • We reserve the right to refuse to groom any pet without reason. This includes if your pet
    appears to be unwell at the time of the appointment.
    <br>
    • We reserve the right to refuse any customer without reason.
    <br>
    • We reserve the right to cancel a groom if in our opinion it could affect the pet’s welfare to start or
    continue a groom or if there is a risk of injury to our grooming colleagues.
    <br>
    • We will not groom any dog that is of a breed or type listed as a banned breed and/or requiring a
    certificate exemption under any dangerous dogs legislation applicable in the country where the
    relevant salon is located.
  </p>

  <h3 class="mat-h3"><b>Matted Dogs and Clipping Policy</b></h3>

  <p class="mat-body-1">
    • We will endeavour to groom your pet to your requirements, which will be discussed during your pet’s consultation,
    however if your pet’s coat is excessively matted, the matted coat will be clipped (shaved) out. Depending on the
    severity of the matted coat, there may be an additional charge in addition to the grooming price, with targeted
    de-matting (such as under the legs and behind the ears) at £5 or general de-matting over the body at £10 for up to a
    maximum of 15 minutes. After this 15 minute point, we will recommend a clip off for the dog’s welfare as per our
    Clipping Policy, which means that it may not be possible to achieve the style you desire. Our primary concern is to
    relieve any distress or discomfort your pet may be experiencing due to their severely or excessively matted coat.
    Should your pet come in heavily matted, due to the time it takes, and special care needed to remove this, you will
    be required to agree to this within the contract; your groomer will explain this and it carries a £10 additional
    charge to the grooming price. You will be informed of any additional costs and why the groomer has advised this
    during your consultation. Therefore, please refer to our Clipping Policy, which is available in our grooming salons,
    for further details.<br></p>

  <h3 class="mat-h3"><b>Double Coated Breeds</b></h3>

  <p class="mat-body-1">
    • We will not clip off any dog that has a double coat without veterinary direction supplied to us in writing.
    Clipping a double coat can have a detrimental effect on a dog’s coat regrowth, leaving the dog with no protection
    from the elements. We can only clip out small areas to make the dog feel comfortable (i.e., at the groin or under
    the armpit).<br></p>
  <h3 class="mat-h3"><b>Two Colleague Groom</b></h3>

  <p class="mat-body-1">
    • It may be necessary- in our opinion for two groomers to carry out your dog’s groom for example due to your dog’s
    behaviour or to ensure the dog’s welfare (e.g. an older dog). This will be charged per 30 minutes of time taken at
    £10 in such circumstances.<br></p>

  <h3 class="mat-h3"><b>Extra Care</b></h3>

  <p class="mat-body-1">
    • Pets which are pregnant or in season will not be accepted for a groom.
    <br>
    • If your pet is found to have fleas, we will charge a fee of £12 in addition to the cost of the groom, where your
    pet will be bathed in a flea shampoo and the salon disinfected.
    <br>• If your pet is found to have a tick, we will charge a removal fee of £3 in addition to the cost of the groom.
    Please see your vet if you suspect that your pet has a tick, as they are known carriers of disease and may require
    further treatment.

    <br>• Some salons may use a drying cabinet. It is the owners responsibility to inform the groomer if their pet has
    any conditions (medical or not) that may exclude them from using the cabinet. Any pet being placed in the cabinet is
    done so entirely at the owner’s risk.

    <br>• If in our opinion we believe that your pet requires immediate veterinary attention whilst in our care, you
    agree that your pet will receive this attention and that you will be liable for any resulting costs.

    <br>• Any dogs that are aggressive towards a member of the grooming team or another pet may need to be muzzled. It
    is the owner’s responsibility to inform the groomer if they do not wish their pet to be restrained or muzzled.
    Refusal may result in the groom not taking place.

    <br>• We strongly advise you to insure your pet. Whilst we take all reasonable precautions, your pet is left with us
    entirely at your own risk and we will not accept any responsibility for any loss, injury, death or illness suffered
    by your pet whilst in our care, except to the extent that we are unable to limit or exclude our liability.
    <br>• We will not be liable for any loss of or damage to personal items.

    <br>• Any complaints regarding the Grooming salon must be directed, in the first instance, to
    the Pets Grooming Salon Manager and our Customer Services department via our
    website.

    <br>• We reserve the right to amend these terms and conditions from time to time without notice.
    <a href="https://www.petsathome.com" target="_blank">www.petsathome.com</a>.

    <br>• We reserve the right to amend these terms and conditions without notice occasionally.
  </p>

  <h3 class="mat-h3"><b>Pets Grooming Pets Club App Rewards - additional terms and conditions</b></h3>

  <p class="mat-body-1">
    • Pets Grooming Pets Club App Reward (the "reward card") is promoted by Pets at Home Ltd, Epsom Avenue, Stanley
    Green Trading Estate. Handforth, Cheshire, SK9 3RN (the "Promoter"). These Terms and Conditions apply to the reward,
    and by Showing your Pets Club App in one of our salons, you are signing up for the reward and agreeing to comply
    with these Terms and Conditions.

    <br>• The reward is exclusively for members of the Pets at Home Pets Club. Customers who are not already members can
    sign up in the salon or online before joining the reward programme at petsathome.com/vip.

    <br>• The reward is only available via the Pets Club App. An applicable smartphone or App-enabled device is required
    to join the scheme.
    <br>• The reward on your App and your Pets Club must be presented at purchase, for acquiring stamps, and when
    redeeming the full card.

    <br>• The reward can only be redeemed in Pets Grooming salons and cannot be used at Pets at Home tills or online at
    petsathome.com

    <br>• The reward cannot be used in conjunction with another promotional offer or discount.

    <br>• We reserve the right to remove customers who do not comply with these terms and conditions and/or
    intentionally abuse the scheme from the scheme. Examples of abuse include, however, but are not limited to, using or
    sharing screenshots of the voucher or redeeming more free grooms than those earned.

    <br>• The reward cannot be sold or exchanged for cash.

    <br>• A refundable deposit of £15 will be required for bookings.

    <br>• Customers can choose to leave the reward at any time. 
    <br>• We reserve the right to change these Terms and Conditions at any time by giving reasonable notice for legal,
    regulatory, business or policy reasons.
  </p>
</mat-dialog-content>
