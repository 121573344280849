// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    PARSE_APP_ID: 'nkDBv1xNdeRFxkkhEHaBrkdjyR0pXNyQ0GYYayJo',
    serverURL: 'https://petmania-staging.api.vetbooker.com/parse',
    GROOM: false,
    VETS_4_PETS: false,
    apiKey: null,
    CLINIC_CODE: '143817',
    BACKEND_URL: 'https://petmania-staging.api.vetbooker.com/parse',
    API_URL: 'https://petmania-staging.api.vetbooker.com/',
    sentryApiCode: '',
    gtag_code: 'G-SWJR2MD78Y',
    stripe_key: 'pk_test_51J2YxlCkXt31lh5FBdQ2ke0lxvnKi7YeXJHoH2gdkJJgnHYI2J6NQosQDQL3UZVQ5vVLQaNywglGFBTEzElDJOEc00voGz82qA',
  };

  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
