import { environment } from './../../../environments/environment';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {VbClient} from '@appyvet/vetbooker-definitions/dist/client';

export const SURNAME: string = "Surname";
export const FIRST_NAME: string = "First Name";
export const TITLE: string = "Title";

export interface ClientName {
  title: string;
  firstName: string;
  surname: string;
}

export interface ClientFormItem {
  client: ClientName;
  form: UntypedFormGroup;
  firstLoad?: boolean;
}

@Component({
  selector: 'app-name-entry',
  templateUrl: './name-entry.component.html',
  styleUrls: ['./name-entry.component.scss']
})
export class NameEntryComponent implements OnInit {
  @Input() hideCancel: boolean;
  @Input() client: VbClient;
  @Input() isRegForm: boolean;
  @Input() firstNameRequired: boolean;
  @Input() regex: string;
  @Output() nameUpdateChange = new EventEmitter();
  @Output() onSave = new EventEmitter<ClientFormItem>();
  nameForm: UntypedFormGroup;
  includeTitle = (environment.GROOM ?? environment.VETS_4_PETS) ? false : true;
  titles: string[] = ["Mr", "Mrs", "Miss", "Ms", "Mr & Mrs", "Dr", "Lord", "Lady"];
  name_validation_messages = {
    'title': [
      {type: 'required', message: 'You must select a title'}
    ],
    'firstName': [
      {type: 'required', message: 'First name is required'},
      {type: 'pattern', message: 'First name can only contain alphanumeric characters'},
    ],
    'surname': [
      {type: 'required', message: 'Last name is required'},
      {type: 'pattern', message: 'Last name can only contain alphanumeric characters'},
    ],
  };
  TITLE = TITLE;
  FIRST_NAME = FIRST_NAME;
  SURNAME = SURNAME;

  constructor() {
  }

  ngOnInit(): void {
    if (this.includeTitle) {
      this.nameForm = new UntypedFormGroup({
        'Title':  new UntypedFormControl(this.client ? this.client.title : '', [Validators.required]),
        'First Name': new UntypedFormControl(this.client ? this.client.firstName : ''),
        'Surname': new UntypedFormControl(this.client ? this.client.surname : '', [Validators.required, Validators.pattern(this.regex)]),
      });
    } else {
      this.nameForm = new UntypedFormGroup({
        'First Name': new UntypedFormControl(this.client ? this.client.firstName : ''),
        'Surname': new UntypedFormControl(this.client ? this.client.surname : '', [Validators.required, Validators.pattern(this.regex)]),
      });
    }

    if (this.firstNameRequired) {
      this.nameForm.get(FIRST_NAME).setValidators([Validators.required, Validators.pattern(this.regex)])
    }

    this.onSave.emit({client: this.client, form: this.nameForm, firstLoad: true});
  }

  cancelUpdate() {
    this.nameUpdateChange.emit(false);
  }

  saveClient() {
    this.onSave.emit({
      form: this.nameForm, client: {
        title: this.includeTitle ? this.nameForm.get(TITLE).value : null,
        firstName: this.nameForm.get(FIRST_NAME).value,
        surname: this.nameForm.get(SURNAME).value
      }
    });
  }
}
