<form [formGroup]="nameForm">
  <div class="content-text" fxLayout="row" fxLayout.xs="column">
    <ng-container *ngIf="includeTitle">
      <mat-form-field appearance="outline" fxFlex="20" fxFlex.xs="100">
        <mat-label>{{TITLE}}</mat-label>
        <mat-select [formControlName]="TITLE" required>
          <mat-option *ngFor="let title of titles" [value]="title">{{title}}</mat-option>
        </mat-select>
        <mat-error *ngFor="let validation of name_validation_messages.title">
          <mat-error
            *ngIf="nameForm.get(TITLE).hasError(validation.type) "
            class="error-message">{{validation.message}}</mat-error>
        </mat-error>
      </mat-form-field>
    </ng-container>
    <mat-form-field appearance="outline" [fxFlex]="includeTitle ? 40 : 50" fxFlex.xs="100">
      <mat-label>{{FIRST_NAME}}</mat-label>
      <input [formControlName]="FIRST_NAME" [required]="firstNameRequired" matInput>
      <mat-error *ngFor="let validation of name_validation_messages.firstName">
        <mat-error
          *ngIf="nameForm.get(FIRST_NAME).hasError(validation.type)"
          class="error-message">{{validation.message}}</mat-error>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" [fxFlex]="includeTitle ? 40 : 50" fxFlex.xs="100">
      <mat-label>{{SURNAME}}</mat-label>
      <input [formControlName]="SURNAME" matInput required>
      <mat-error *ngFor="let validation of name_validation_messages.surname">
        <mat-error *ngIf="nameForm.get(SURNAME).hasError(validation.type) "
                   class="error-message">{{validation.message}}</mat-error>
      </mat-error>
    </mat-form-field>
  </div>
</form>
<div fxLayout="row" [fxLayoutAlign]="hideCancel ? 'end center' :
'space-between center'">
  <button (click)="cancelUpdate()" *ngIf="!hideCancel" mat-stroked-button>
    Cancel
  </button>
  <button (click)="saveClient()" [disabled]="!nameForm.valid" color="primary"
          mat-raised-button>{{isRegForm ? 'next' : 'save'}}</button>
</div>
