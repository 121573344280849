<div class="padding-10 container" fxLayout="column">
  <h1 class="section-header">{{editPatient ? 'Edit pet details' : 'Add a pet'}}</h1>
  <ng-container *ngIf="(loading$ | async); else addBlock">
    <div fxLayout="row" fxLayoutAlign="center center">
      <mat-progress-spinner color="primary" diameter="40"
                            mode="indeterminate"></mat-progress-spinner>
      <p class="content-text padding-20">Saving pet details</p></div>
  </ng-container>
  <ng-template #addBlock>
    <mat-card>
      <app-add-patient (savePatient)="savePatient($event)" [breeds]="breeds" [isNewPet]="!editPatient"
                       [showColors]="showColors" [showMicrochip]="showMicrochip" [showIsPetImported]="showIsPetImported"
                       [colors]="colors" [editPatient]="editPatient" [regex]="alphanumericRegexp" [species]="species"
                       [clinicCode]="clinicCode"
                       [clinicPmsName]="clinicPmsName"
      ></app-add-patient>
      <ng-container *ngIf="error$ | async">
        <p class="content-text accent-text-color">There has been an error</p>
      </ng-container>
    </mat-card>
  </ng-template>
</div>
