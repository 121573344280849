import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { SignupService } from "../signup/signup.service";
import { PasswordMatchErrorMatcher } from "../../components/password-matching-validator";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../auth/auth.service";
import { Auth } from "@aws-amplify/auth";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { AlertDialogComponent } from "../../components/shared/alert-dialog/alert-dialog.component";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { LoginService } from "src/app/services/login-service.service";
import { LoginClinicDetails } from "@appyvet/vetbooker-definitions/dist/clinic_details";
import { MaterialCssVarsService } from "angular-material-css-vars";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  accountValidationMessages = this.signupService.accountValidationMessages;
  matcher = new PasswordMatchErrorMatcher();
  loading$ = new BehaviorSubject<boolean>(false);
  error$ = new BehaviorSubject<string>(null);
  resetPasswordForm: UntypedFormGroup;
  private token: string;
  isVets4Pets = environment.VETS_4_PETS;
  private passwordRegexp: RegExp = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{10,}$"
  );
  private passwordLength: number = environment.VETS_4_PETS ? 10 : 8;
  email: string;
  private resendPin = 0;
  private pinAttempts = 0;
  resendLoading$ = new BehaviorSubject<boolean>(false);
  clinicDetails: LoginClinicDetails;

  constructor(
    private signupService: SignupService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private gaService: GoogleAnalyticsService,
    private loginService: LoginService,
    private materialCssVarsService: MaterialCssVarsService,
  ) {
    this.route.queryParamMap.subscribe((params) => {
      this.token = params.get("token");
      this.signupService.setEmail(params.get("email"));
      this.email = params.get("email");
    });
  }

  checkPasswords(group: UntypedFormGroup) {
    // here we have the 'passwords' group
    const pass = group.get("password").value;
    const confirmPass = group.get("confirmPassword").value;
    return pass === confirmPass ? null : { mismatch: true };
  }

  ngOnInit(): void {
    const passwordValidators = [Validators.required];
    if (environment.VETS_4_PETS) {
      passwordValidators.push(Validators.pattern(this.passwordRegexp));
    } else {
      passwordValidators.push(Validators.minLength(this.passwordLength));
    }
    this.resetPasswordForm = new UntypedFormGroup(
      {
        password: new UntypedFormControl(null, passwordValidators),
        confirmPassword: new UntypedFormControl(null, passwordValidators),
      },
      { validators: this.checkPasswords }
    );
    if (this.isVets4Pets) {
      this.email = this.authService.username;
      if (!this.email) {
        this.resetPasswordForm.addControl(
          "email",
          new UntypedFormControl(null, [Validators.email, Validators.required])
        );
      }
      this.resetPasswordForm.addControl(
        "verificationCode",
        new UntypedFormControl(null, [
          Validators.minLength(6),
          Validators.maxLength(6),
          Validators.required,
        ])
      );
    }

    const clinicCode = this.authService.getClinicCode();
    if (clinicCode) {
      this.loginService.getClinicDetails(clinicCode, false).subscribe({
        next: (clinicDetails: LoginClinicDetails) => {
          const primaryHex = "#3F51B5";
          const accentHex = "#E91E63";

          this.materialCssVarsService.setPrimaryColor(
            clinicDetails.themeSettings.primaryColor || primaryHex
          );
          this.materialCssVarsService.setAccentColor(
            clinicDetails.themeSettings.accentColor || accentHex
          );
        },
      });
    }
  }

  async resetPassword() {
    this.error$.next(null);
    if (!this.isVets4Pets || this.pinAttempts < 3) {
      let token = this.token;
      if (this.isVets4Pets) {
        token = this.resetPasswordForm.get("verificationCode").value;
      }
      this.loading$.next(true);
      try {
        await this.signupService.resetPassword(
          token,
          this.resetPasswordForm.get("password").value,
          this.email || this.resetPasswordForm.get("email").value
        );
        this.loading$.next(false);
        this.gaService.event("Password Reset", "Password Reminder");
      } catch (e) {
        this.pinAttempts++;
        this.snackBar.open(
          "Password reset unsuccessful, please try again",
          null,
          { duration: 5000 }
        );
        this.error$.next(e.message);
        this.loading$.next(false);
        this.gaService.event(
          "Password Reset Failed",
          "Password Reminder",
          "Attempt " + this.pinAttempts
        );
      }
    } else {
      this.matDialog.open(AlertDialogComponent, {
        data: {
          message: "PIN Incorrect, please request a new PIN",
          confirmationText: "Got it",
        },
      });
    }
  }

  resendVerification() {
    if (this.resendPin < 3) {
      this.resendLoading$.next(true);
      this.resendPin++;
      Auth.forgotPassword(
        this.email || this.resetPasswordForm.get("email").value
      )
        .then(() => {
          this.resendLoading$.next(false);
          this.pinAttempts = 0;
          this.snackBar.open("New PIN successfully requested", null, {
            duration: 5000,
          });
        })
        .catch((err) => {
          this.resendLoading$.next(false);
          this.snackBar.open("Error requesting new pin", null, {
            duration: 10000,
          });
        });
    } else {
      this.matDialog.open(AlertDialogComponent, {
        data: {
          message: "You may only request a new PIN 3 times.",
          confirmationText: "Got it",
        },
      });
    }
  }
}
