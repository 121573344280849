import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable, of, Subscription} from 'rxjs';
import {first, map, startWith, tap} from 'rxjs/operators';
import {format, parseISO, subMonths, subYears} from 'date-fns';
import {PetFormItem} from '../add-patient-page/add-patient-page.component';
import {BreedItem, ColorItem, SpeciesItem, VbExtendedPatient} from '@appyvet/vetbooker-definitions/dist/patient';
import {RequireMatch} from '../../components/common-components/requireAutocompleteMatch';
import {environment} from '../../../environments/environment';
import { BreedColorService } from '../breed-color.service';
import { shouldGetBreedsAndColoursDynamically } from 'src/app/helpers/should-get-breeds-and-colours-dynamically';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.scss']
})
export class AddPatientComponent implements OnInit, OnDestroy {
  addPetForm: UntypedFormGroup;
  @Input() isNewPet: boolean;
  @Input() editPatient: VbExtendedPatient;
  @Output() savePatient = new EventEmitter<PetFormItem>();
  @Output() formChanged = new EventEmitter<PetFormItem>();
  @Output() cancel = new EventEmitter<boolean>();
  @Input() petId: string;
  isGroomRoom = environment.GROOM;
  selectedSpecies: SpeciesItem;
  selectedColor: ColorItem;
  knownBirthdate = true;
  selectedSex: string;
  neutered: boolean;
  filteredBreeds: Observable<BreedItem[]>;
  filteredColors: Observable<ColorItem[]>;
  patientValidationMessages = {
    name: [
      {type: 'required', message: 'Pet name is required'},
      {type: 'pattern', message: 'Pet\'s name can only contain alphanumeric characters'}
    ],
    dateOfBirth: [
      {type: 'matDatepickerMax', message: 'Date of birth must be before today'},
      {type: 'pattern', message: 'Date of birth must be in the format \'DD/MM/YYYY\''}
    ],
    breed: [
      {type: 'required', message: 'Breed is required'},
      {type: 'pattern', message: 'Breed type can only contain alphanumeric characters'},
      {type: 'incorrect', message: 'Breed must be selected from the options available'},
    ],
    color: [
      {type: 'required', message: 'Color is required'},
      {type: 'pattern', message: 'Color can only contain alphanumeric characters'},
      {
        type: 'incorrect',
        message: 'Color must be selected from the options available, if no options appear, leave blank'
      }
    ],
    microchip: [
      {type: 'pattern', message: 'Microchip number cannot contain special characters'},
    ],
    isPetImported: [
      {type: 'required', message: '"Is Pet Imported?" is required'},
    ]
  };
  @Input() clinicCode: string;
  @Input() clinicPmsName: string;
  @Input() breeds: BreedItem[];
  @Input() colors: ColorItem[];
  @Input() regex: string;
  @Input() isRegistration: boolean;
  @Input() species: SpeciesItem[];
  @Input() showInsured: boolean;
  @Input() showLastVac: boolean;
  @Input() showMicrochip: boolean;
  @Input() showIsPetImported: boolean;
  @Input() showColors: boolean;
  breedColors: ColorItem[] = [];
  private speciesBreedsList: BreedItem[];
  private formChangedSubscription: Subscription;
  private yearsSubscription: Subscription;
  private monthsSubscription: Subscription;
  neuteredDirty: boolean;
  today = new Date();
  selectedBreed: BreedItem;
  minDob = new Date('1980-01-01');

  protected isLoadingBreeds = false;
  protected isLoadingColours = false;
  private cachedBreeds: {[key: string]: BreedItem[]} = {};
  private cachedColors: {[key: string]: ColorItem[]} = {};

  constructor(private breedService: BreedColorService) {}

  ngOnInit(): void {
    const startDateOfBirth = this.editPatient ? parseISO(this.editPatient?.dateOfBirth) : new Date();
    this.addPetForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.editPatient?.name, [Validators.required, Validators.pattern(this.regex)]),
      breed: new UntypedFormControl({
        value: this.editPatient?.breedItem,
        disabled: !this.selectedSpecies
      }, [Validators.required, RequireMatch]),
      color: new UntypedFormControl(this.editPatient?.color, this.showColors ? [RequireMatch] : null),
      dateOfBirth: new UntypedFormControl(startDateOfBirth, [Validators.required]),
      microchip: new UntypedFormControl(this.editPatient?.microchip, [Validators.pattern(this.regex)]),
      months: new UntypedFormControl(0),
      years: new UntypedFormControl(0),
      lastVac: new UntypedFormControl(this.editPatient?.lastVaccinated),
      insurance: new UntypedFormControl(this.editPatient?.insurer),
      sex: new UntypedFormControl(this.editPatient?.sex, [Validators.required]),
      neutered: new UntypedFormControl(this.editPatient?.desexed, [Validators.required]),
      species: new UntypedFormControl(this.editPatient?.breedItem?.speciesItem, [Validators.required]),
      isPetImported: new UntypedFormControl(this.editPatient?.isPetImported)
    });
    this.init()
  }

  async init(): Promise<void> {
    if (this.editPatient) {
      if (this.editPatient.breedItem) {
        if(this.breeds?.length){
          this.editPatient.breedItem = this.breeds.find(breed => breed.species === this.editPatient.breedItem.species && breed.name === this.editPatient.breedItem?.name)
        }
        if(this.colors?.length){
          this.editPatient.colorItem = this.colors.find(color => {
            if (color.name === this.editPatient.color) {
              if (color.speciesId) {
                return color.speciesId === this.editPatient.breedItem?.speciesItem.code
              } else {
                return true;
              }
            }
          });
        }
        if (this.editPatient.breedItem.speciesItem) {
          await this.selectedSpeciesChanged(this.editPatient.breedItem.speciesItem, this.editPatient.breedItem);
        }
        
        await this.breedSelected(this.editPatient.breedItem, this.editPatient.colorItem);
      } else if (this.species.length === 1) {
        this.selectedSpecies = this.species[0];
        await this.selectedSpeciesChanged(this.selectedSpecies);
      }
      this.selectedSex = this.editPatient.sex;
      if (this.editPatient.desexed !== undefined) {
        this.neuteredChanged(this.editPatient.desexed);
      }
      this.addPetForm.markAllAsTouched();
    } else if (this.species.length === 1) {
      this.selectedSpecies = this.species[0];
      await this.selectedSpeciesChanged(this.selectedSpecies);
    }
    if (this.showColors) {
        this.filteredColors = this.addPetForm.get('color').valueChanges
          .pipe(
            startWith(this.editPatient ? this.editPatient.color : ''),
            map(value => this._filterColors(value))
          );
      if(this.editPatient) {
        if (this.selectedColor) {
          this.filteredColors = of([this.selectedColor])
        } else {
          this.filteredColors = of([])
        }
      }

      this.addPetForm.get('breed').valueChanges
        .pipe(
          tap(() => {
            this.selectedColor = null;
            this.addPetForm.get('color').setValue(null);
            this.filteredColors = of(this._filterColors(''));
          })
        );
    }

    this.yearsSubscription = this.addPetForm.get('years').valueChanges.subscribe(() => {
      this.addPetForm.get('dateOfBirth').setValue(this.getDateOfBirth());
    });
    this.monthsSubscription = this.addPetForm.get('months').valueChanges.subscribe(() => {
      this.addPetForm.get('dateOfBirth').setValue(this.getDateOfBirth());
    });
    if (this.formChanged) {
      // Send initial status change with updated (empty) form, but can't update patient or causes error. Ensures step
      // shows as errored when skip stepper in reg form.
      this.formChanged.emit({
        patient: this.getPatientFromForm(),
        form: this.addPetForm,
        id: this.petId,
        onlyUpdateForm: true
      });
      this.formChangedSubscription = this.addPetForm.valueChanges.subscribe(() => {
        this.formChanged.emit({patient: this.getPatientFromForm(), form: this.addPetForm, id: this.petId});
      });
    }

    if (this.editPatient) {
      if (this.editPatient.breedItem) {
        this.breedSelected(this.editPatient.breedItem, this.editPatient.colorItem);
      }
    }
  }

  onIsPetImportedChange(event: MatCheckboxChange): void {
    const value = event.checked ? 'Yes' : 'No';
    this.addPetForm.get('isPetImported')?.setValue(value);
  }

  displayFn(breed: BreedItem): string {
    return breed && breed.name ? breed.name : '';
  }

  getDateOfBirth(): Date {
    const startDateYears = subYears(new Date(), this.addPetForm.get('years').value);
    return subMonths(startDateYears, this.addPetForm.get('months').value);
  }

  onCancel() {
    this.cancel.emit(true);
  }

  getPatientFromForm(): VbExtendedPatient {
    return {
      breedItem: this.addPetForm.get('breed').value,
      color: this.addPetForm.get('color').value?.name,
      colorItem: this.addPetForm.get('color').value,
      species: this.selectedSpecies?.name,
      dateOfBirth: this.addPetForm.get('dateOfBirth').value ? format(this.addPetForm.get('dateOfBirth').value,
        'yyyy-MM-dd') : null,
      desexed: this.neutered,
      name: this.addPetForm.get('name').value,
      patientNumber: null,
      sex: this.selectedSex,
      microchip: this.addPetForm.get('microchip').value,
      insurer: this.addPetForm.get('insurance').value,
      lastVaccinated: this.addPetForm.get('lastVac').value,
      isPetImported: this.addPetForm.get('isPetImported').value,
    };
  }

  save() {
    const rez = {patient: this.getPatientFromForm(), form: this.addPetForm, id: this.petId}
    this.savePatient.emit(rez);
  }

  async selectedSpeciesChanged(species: SpeciesItem, existingBreed?: BreedItem) {
    this.selectedSpecies = species;
    this.breeds = await this.refreshBreeds(species)
    // This filter is faster than a for loop oddly.
    this.speciesBreedsList = this.breeds.filter(breed => !breed.speciesItem || breed.speciesItem.code.toString() === species.code.toString());
    this.filteredBreeds = this.addPetForm.get('breed').valueChanges
      .pipe(
        startWith(existingBreed ? existingBreed.name : ''),
        map(value => this._filterBreeds(value))
      );
    if (!existingBreed) {
      this.addPetForm.get('breed').reset();
    }
    this.addPetForm.get('breed').enable();
    this.addPetForm.get('species').setValue(species);
    if (existingBreed) {
      this.addPetForm.get('breed').setValue(existingBreed);
    }
  }

  private _filterBreeds(value: string): BreedItem[] {
    // When value finally selected from autocomplete, sends a breeditem not a string, so check here to avoid error.
    if (value && typeof value === 'string' && value.length > 2) {
      const filterValue = value.toLowerCase();
      // Note - this can be a big array of object so use fastest loop possible.
      let length = this.speciesBreedsList.length;
      const returnArray = [];
      while (length--) {
        if (this.speciesBreedsList[length].name && this.speciesBreedsList[length].name.toLowerCase()
          .indexOf(filterValue) !== -1) {
          returnArray.push(this.speciesBreedsList[length]);
        }
      }
      return returnArray.sort((a, b) => a.name.localeCompare(b.name));
    }
  }
  

  private _filterColors(value: string | ColorItem): ColorItem[] {
    if (!value) {
      // If no search value provided, return all colors for the selected breed
      return this.breedColors.filter(color => !color.breedId || color.breedId === this.selectedBreed?.id);
    }
  
    let filterValue: string;
    if (typeof value !== 'string') {
      filterValue = value.name.toLowerCase();
    } else {
      filterValue = value.toLowerCase();
    }
  
    // Filter the colors based on the search value
    const filteredColors = this.breedColors.filter(option =>
      option.name.toLowerCase().includes(filterValue) && (!option.breedId || option.breedId === this.selectedBreed.id)
    );
  
    // Separate exact matches
    const exactMatches = filteredColors.filter(option => option.name.toLowerCase().startsWith(filterValue));
  
    // Remaining matches
    const remainingMatches = filteredColors.filter(option => !option.name.toLowerCase().startsWith(filterValue));
  
    // Concatenate results: exact matches first, then remaining matches
    const orderedResults = [...exactMatches, ...remainingMatches];
    return orderedResults;
  }
  

  ngOnDestroy(): void {
    this.formChangedSubscription?.unsubscribe();
    this.monthsSubscription?.unsubscribe();
    this.yearsSubscription?.unsubscribe();
  }

  neuteredChanged(status: boolean) {
    this.neutered = status;
    this.addPetForm.get('neutered').setValue(status);
    this.neuteredDirty = true;
  }

  sexChanged(sex: string) {
    this.selectedSex = sex;
    this.addPetForm.get('sex').setValue(sex);
  }

  async breedSelected(value: BreedItem, existingColor?: ColorItem) {
    this.selectedBreed = value;
    this.colors = [...await this.refreshColors(value, this.selectedSpecies)]

    this._filterBreeds(existingColor?.name);
    this.breedColors = this.colors.filter(color=> !color.breedId || color.breedId === value.id);
    this.addPetForm.get('color').reset();
    this.selectedColor = null;
    this.filteredColors = of(this.breedColors);
    if (this.showColors) {
      this.filteredColors = this.addPetForm.get('color').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterColors(value))
        );
    }
    if (!existingColor) {
      //
    }else{
      this.addPetForm.get('color').setValue(existingColor);
      this.selectedColor = existingColor;
    }
    this.addPetForm.get('color').enable();
  }

  private async refreshBreeds(species: SpeciesItem): Promise<BreedItem[]> {
    if(shouldGetBreedsAndColoursDynamically(this.clinicPmsName) && species?.code){
      if(this.cachedBreeds[species.code]?.length){
        return this.cachedBreeds[species.code];
      }
      else{
        this.isLoadingBreeds = true;
        this.cachedBreeds[species.code] = await this.breedService.getBreedsBySpecies(species.code, this.clinicCode) || []
        this.isLoadingBreeds = false;
        return this.cachedBreeds[species.code]
      }
    }
    else{
      return this.breeds;
    }
  }

  private async refreshColors(breed: BreedItem, speciesItem: SpeciesItem): Promise<ColorItem[]> {
    if(shouldGetBreedsAndColoursDynamically(this.clinicPmsName) && breed?.id){
      if(this.cachedColors[breed.id]?.length){
        return this.cachedColors[breed.id];
      }
      else{
        this.isLoadingColours = true;
        this.cachedColors[breed.id] = await this.breedService.getColoursByBreed(breed.id, speciesItem, this.clinicCode) || []
        this.isLoadingColours = false;
        return this.cachedColors[breed.id]
      }
    }
    else{
      return this.colors;
    }
  }

  compareColors(color1: ColorItem, color2: ColorItem) {
    // used to display the selected color
    return (color1.id == color2.id);
  }
}
